<template>
  <div class="home-container">
    <el-card shadow="always" style="margin-bottom:30px;">
      <div class="emq-title">
        Configuration
      </div>
      <el-form ref="configForm" hide-required-asterisk size="small" label-position="top" :model="connection">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="host" label="Host">
              <el-row :gutter="10">
                <el-col :span="7">
                  <el-select v-model="connection.protocol" @change="handleProtocolChange">
                    <el-option label="mqtt://" value="mqtt"></el-option>
                    <el-option label="ws://" value="ws"></el-option>
                    <el-option label="wss://" value="wss"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="17">
                  <el-input v-model="connection.host"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="port" label="Port">
              <el-input v-model.number="connection.port" type="number" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="mac" label="mac地址">
              <el-input v-model="mac" placeholder="" @change="macChange"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="API key" label="API key">
              <el-input v-model="apikey" placeholder="api key"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="clientId" label="Client ID">
              <el-input v-model="connection.clientId"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="username" label="Username">
              <el-input v-model="connection.username"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item prop="password" label="Password">
              <el-input type="password" v-model="connection.password"></el-input>
            </el-form-item>
          </el-col> -->

          <el-col :span="24">
            <el-button type="success" size="small" class="conn-btn" style="margin-right: 20px;"
              :disabled="client.connected" @click="createConnection" :loading="connecting">
              {{ client.connected ? 'Connected' : 'Connect' }}
            </el-button>

            <el-button v-if="client.connected" type="danger" size="small" class="conn-btn" @click="destroyConnection">
              Disconnect
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card shadow="always" style="margin-bottom:30px;">
      <div class="emq-title">
        Subscribe
      </div>
      <el-form ref="subscription" hide-required-asterisk size="small" label-position="top" :model="subscription">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="topic" label="Topic">
              <el-input v-model="subscription.topic"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="qos" label="QoS">
              <el-select v-model="subscription.qos">
                <el-option v-for="qos in qosList" :key="qos" :label="qos" :value="qos"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button :disabled="!client.connected" type="success" size="small" class="subscribe-btn"
              @click="doSubscribe">
              {{ subscribeSuccess ? 'Subscribed' : 'Subscribe' }}
            </el-button>
            <el-button :disabled="!client.connected" type="success" size="small" class="subscribe-btn"
              style="margin-left:20px" @click="doUnSubscribe" v-if="subscribeSuccess">
              Unsubscribe
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card shadow="always" style="margin-bottom:30px;">
      <div class="emq-title">
        助手
      </div>
      <el-form ref="publish" hide-required-asterisk size="small" label-position="top" :model="publish">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="prompt" label="Prompt">
              <el-select v-model="promptId" @change="promptChange">
                <el-option v-for="item in promptSelect" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="windowId" label="会话ID">
              <el-input v-model="windowId" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button type="success" size="small" class="publish-btn" @click="doCreateWindow">
              创建会话
            </el-button>
          </el-col>
        </el-row>
      </el-form>

    </el-card>
    <el-card shadow="always" style="margin-bottom:30px;">
      <div class="emq-title">
        消息
      </div>
      <el-form ref="publish" hide-required-asterisk size="small" label-position="top" :model="publish">
        <el-row :gutter="20">
          <el-col :span="16">
            <el-form-item prop="payload" label="Payload">
              <el-input v-model="publish.payload"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="qos" label="QoS">
              <el-select v-model="publish.qos">
                <el-option v-for="qos in qosList" :key="qos" :label="qos" :value="qos"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-col :span="24">
        <el-button @click="startRecording" :disabled="isRecording">Start Recording</el-button>
        <el-button @click="stopRecording" :disabled="!isRecording">Stop Recording</el-button>
        <audio v-if="audioUrl" :src="audioUrl" controls></audio>
        <el-button :disabled="!client.connected" type="success" size="small" class="publish-btn" @click="doSendMessage">
          发送
        </el-button>
      </el-col>
    </el-card>
    <el-card shadow="always" style="margin-bottom:30px;">
      <div class="emq-title">
        Receive 当前随机id: {{ this.taskId }}
      </div>
      <el-col :span="20">
        <el-input type="textarea" :rows="30" style="margin-bottom: 15px" v-model="receiveNews" readOnly></el-input>
      </el-col>
      <el-col :span="4">
        <el-button :disabled="!client.connected" type="danger" size="small" class="publish-btn" @click="doStop">
          停止接收
        </el-button>
      </el-col>
    </el-card>
  </div>
</template>

<script>
import mqtt from 'mqtt'
import axios from 'axios'

export default {
  name: 'Home',
  created() {
    let windowId = sessionStorage.getItem("windowId")
    if  (windowId) {
      this.windowId = windowId
    }
  },
  data() {
    return {
      baseUrl: 'https://eb-api.jeemoo.com',
      apikey: '',
      mac: '',
      prompts: [],
      promptSelect: [],
      promptId: undefined,
      windowId: '',
      isRecording: false,
      mediaRecorder: null,
      audioChunks: [],
      audioUrl: null,
      connection: {
        protocol: 'wss',
        host: 'mq-prod.jeemoo.com',
        // ws: 8083; wss: 8084
        port: 61615,
        endpoint: '',
        // for more options, please refer to https://github.com/mqttjs/MQTT.js#mqttclientstreambuilder-options
        clean: true,
        connectTimeout: 30 * 1000, // ms
        reconnectPeriod: 4000, // ms
        clientId:
          'emqx_vue_' +
          Math.random()
            .toString(16)
            .substring(2, 8),
        // auth
        username: '',
        password: 'AC7058FC231BF4BCDFFCD4593F14F454',
      },
      subscription: {
        topic: 'speaker/mac/control',
        qos: 0,
      },
      publish: {
        topic: 'topic/browser',
        qos: 0,
        payload: '你好',
      },
      receiveNews: '',
      qosList: [0, 1, 2],
      client: {
        connected: false,
      },
      subscribeSuccess: false,
      connecting: false,
      retryTimes: 0,
      randomUuid: '',
      taskId: '',
      token: '',
      audioList: {},
      currentAudio: null,
      currentAudioIndex: 0,
      isPlaying: false
    }
  },
  watch: {
    // 监听音频列表的变化，如果有新音频且当前没有播放，则开始播放
    audioList(newList) {
      if (newList.length > 0 && !this.isPlaying) {
        this.playAudios();
      }
    }
  },
  methods: {
    macChange(value) {
      console.log(value)
      this.connection.username = 'eb_' + value
      this.subscription.topic = 'speaker.' + value + '.control'
    },
    promptChange(value) {
      this.prompts.forEach(item => {
        if (item.promptId == value) {
          const audio = new Audio(item.initMessageUrl);
          this.currentAudio = audio;
          this.isPlaying = true;
          audio.play();
        }
      })
    },
    initData() {
      this.client = {
        connected: false,
      }
      this.retryTimes = 0
      this.connecting = false
      this.subscribeSuccess = false
    },
    handleOnReConnect() {
      this.retryTimes += 1
      if (this.retryTimes > 5) {
        try {
          this.client.end()
          this.initData()
          this.$message.error('Connection maxReconnectTimes limit, stop retry')
        } catch (error) {
          this.$message.error(error.toString())
        }
      }
    },
    playAudio(index) {
      if (index >= this.audioList.length) {
        // 如果当前没有更多音频，则等待新音频
        this.isPlaying = false;
        return;
      }
      console.log(this.audioList)
      // 从列表中获取下一个音频并播放
      const audio = new Audio(this.audioList[this.taskId][index]);
      this.currentAudio = audio;
      this.isPlaying = true;
      audio.play();

      audio.onended = () => {
        this.currentAudioIndex++;
        this.playAudio(this.currentAudioIndex);
      };

      audio.onerror = (e) => {
        console.error('音频播放出错:', e);
      };
    },
    playAudios() {
      if (!this.isPlaying) {
        this.currentAudioIndex = 0;
        this.playAudio(this.currentAudioIndex);
      }
    },
    stopPlaying() {
      if (this.currentAudio) {
        this.currentAudio.pause();
        this.currentAudio.currentTime = 0;
        this.currentAudio.onended = null;
        this.currentAudio = null;
        this.isPlaying = false;
      }
      this.currentAudioIndex = 0;
    },
    addAudio(taskId, base64Audio) {
      if (!this.audioList[taskId]) {
        this.audioList[taskId] = []
      }
      // 添加新的Base64音频到列表
      this.audioList[taskId].push(base64Audio);
      console.log(this.audioList)
      // 如果当前没有播放，则开始播放新添加的音频
      if (!this.isPlaying) {
        this.playAudios();
      }
    },
    async startRecording() {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream);
      this.mediaRecorder.start();
      this.isRecording = true;

      this.mediaRecorder.ondataavailable = event => {
        this.audioChunks.push(event.data);
      };

      this.mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
        this.audioUrl = URL.createObjectURL(audioBlob);
        await this.sendAudioToServer(audioBlob);
        this.audioChunks = [];
        this.isRecording = false;
      };
    },
    stopRecording() {
      this.mediaRecorder.stop();
    },
    async sendAudioToServer(audioBlob) {
      const formData = new FormData();
      formData.append('file', audioBlob, 'recording.wav');
      try {
        const response = await fetch(this.baseUrl + '/common/voice/text', {
          method: 'POST',
          body: formData,
          headers:{Authorization: this.apikey}
        });
        const result = await response.json();
        console.log('Transcription result:', result);
        this.publish.payload = result.data.text
      } catch (error) {
        console.error('Error:', error);
      }
    },
    createConnection() {
      try {
        this.connecting = true
        const { protocol, host, port, endpoint, ...options } = this.connection
        const connectUrl = `${protocol}://${host}:${port}${endpoint}`
        this.client = mqtt.connect(connectUrl, options)
        if (this.client.on) {
          this.client.on('connect', () => {
            this.connecting = false
            console.log('Connection succeeded!')
            this.getPrompts()
          })
          this.client.on('reconnect', this.handleOnReConnect)
          this.client.on('error', error => {
            console.log('Connection failed', error)
          })
          this.client.on('message', (topic, message) => {
            let jsonData = JSON.parse(message)
            console.log(jsonData)
            if (jsonData.data.audioData) {
              console.log(this.audioList)
              this.addAudio(this.taskId, jsonData.data.audioData)
            }
            this.receiveNews = this.receiveNews.concat(JSON.stringify(jsonData) + "\n\n")
          })
        }
      } catch (error) {
        this.connecting = false
        console.log('mqtt.connect error', error)
      }
    },
    // subscribe topic
    // https://github.com/mqttjs/MQTT.js#mqttclientsubscribetopictopic-arraytopic-object-options-callback
    doSubscribe() {
      const { topic, qos } = this.subscription
      this.client.subscribe(topic, { qos }, (error, res) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
        this.subscribeSuccess = true
        console.log('Subscribe to topics res', res)
      })
    },
    // unsubscribe topic
    // https://github.com/mqttjs/MQTT.js#mqttclientunsubscribetopictopic-array-options-callback
    doUnSubscribe() {
      const { topic } = this.subscription
      this.client.unsubscribe(topic, error => {
        if (error) {
          console.log('Unsubscribe error', error)
        }
      })
    },
    // publish message
    // https://github.com/mqttjs/MQTT.js#mqttclientpublishtopic-message-options-callback
    doSendMessage() {
      this.doStop()
      this.receiveNews = ''
      this.taskId = Math.random() + ''
      axios.get(this.baseUrl + '/common/mqtt/authToken?mac=' + this.mac,
        {
          headers: { 'Content-type': 'application/json', 'Authorization': this.apikey }
        }).then(res => {
          console.log(res)
          let token = res.data.data.token
          let data = {
            event: "sendMessage",
            data: {
              token: token,
              uuid: this.taskId,
              windowId: this.windowId,
              message: this.publish.payload,
              mac: this.mac,
              isAudio: 1
            }
          }
          axios.post(this.baseUrl + '/common/mqtt/speaker/event', data, {headers: { 'Content-type': 'application/json', 'Authorization': this.apikey }}).then(res=>{
            console.log(res)
            this.playAudios()
          })
        })
    },
    doCreateWindow() {
      axios.post(this.baseUrl + '/common/window/create', { promptId: this.promptId, userId: '123456' }, {
        headers: { Authorization: this.apikey }
      }).then(res => {
        console.log(res)
        this.windowId = res.data.data.windowId
        sessionStorage.setItem("windowId", this.windowId)
      })
    },
    getPrompts() {
      axios.get(this.baseUrl + '/common/prompt/list', {
        headers: { Authorization: this.apikey }
      }).then(res => {
        console.log(res)
        this.prompts = res.data.data
        this.prompts.forEach(item => {
          this.promptSelect.push({ label: item.promptName, value: item.promptId })
        });
      })
    },
    doStop() {
      this.stopPlaying()
      this.audioList = {}
      let data = {
        event:'stopMessage',
        data:{
          uuid: this.taskId
        }
      }
      axios.post(this.baseUrl + '/common/mqtt/speaker/event', data, {headers: { 'Content-type': 'application/json', 'Authorization': this.apikey }}).then(res=>{
            console.log(res)
          })
    },
    // disconnect
    // https://github.com/mqttjs/MQTT.js#mqttclientendforce-options-callback
    destroyConnection() {
      if (this.client.connected) {
        try {
          this.client.end(false, () => {
            this.initData()
            console.log('Successfully disconnected!')
          })
        } catch (error) {
          console.log('Disconnect failed', error.toString())
        }
      }
    },
    handleProtocolChange(value) {
      this.connection.port = value === 'wss' ? '8084' : '8083'
    },
  },
}
</script>

<style lang="scss">
@import url('../assets/style/home.scss');

.home-container {
  max-width: 1100px;
  margin: 0 auto;

  .conn-btn {
    color: #fff;
    background-color: #00b173;
    font-size: 14px;
  }

  .publish-btn {
    margin-bottom: 20px;
    float: right;
  }

  .el-button--success {
    background-color: #34c388 !important;
    border-color: #34c388 !important;
    font-size: 14px !important;
  }

  .el-button--danger {
    background-color: #f5222d !important;
    border-color: #f5222d !important;
  }

  .el-form-item {
    &.is-error {

      .el-input__inner,
      .el-textarea__inner {
        box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
      }
    }

    &.is-success {

      .el-input__inner,
      .el-textarea__inner {
        border-color: #34c388 !important;
      }
    }
  }
}
</style>
